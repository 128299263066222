import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Stack } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";

interface Props {
  link?: string | number;
}

function GoBackButton({ link = "/" }: Props): JSX.Element {
  return (
    <Link to={link}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          bgcolor: "background.default",
          filter: "drop-shadow(0 3px 2px #00000070)",
        }}
      >
        <Box height="24px">
          <ArrowBackIosNewIcon />
        </Box>
      </Stack>
    </Link>
  );
}

export default GoBackButton;
