import { Box, Stack, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";

interface Props {
  children: React.ReactNode;
}

function Controls({ children }: Props): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box position="absolute" top={0} left={6} zIndex={1}>
      {isMobile && <Toolbar />}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          marginTop: "10px",
        }}
      >
        {children}
      </Stack>
    </Box>
  );
}

export default Controls;
