import { graphql, useStaticQuery } from "gatsby";

import { LpsIconLabelsQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const LPSIconLabelsQuery =
  (): LpsIconLabelsQuery["allLocalizableJson"]["edges"][number]["node"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } = useStaticQuery<LpsIconLabelsQuery>(graphql`
      query LPSIconLabels {
        allLocalizableJson {
          edges {
            node {
              language
              LPSIconLabels {
                dentist
                general
                pediatrician
                pharmacy
              }
            }
          }
        }
      }
    `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node)[0];
  };

export default LPSIconLabelsQuery;
